<template>
  <autocomplete
    ref="autocomplete"
    :base-class="baseClass"
    v-bind="{ ...$props, ...$attrs }"
    @submit="handleSubmit"
    v-on:input="handleInput"
  ></autocomplete>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";

export default {
  name: "Autocomplete",
  components: { autocomplete: Autocomplete },
  props: {
    index: {
      type: Number,
      required: false
    },
    overwriteClass: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      selectedValue: null
    };
  },
  // watch: {
  //   selectedValue: {
  //     immediate: false,
  //     handle(value) {
  //       console.log(value);
  //       this.$emit("change", value);
  //     }
  //   }
  // },
  computed: {
    baseClass() {
      return this.overwriteClass ? this.overwriteClass : "autocomplete";
    }
  },
  methods: {
    handleSubmit(value) {
      this.selectedValue = value;
      this.$emit("change", this.selectedValue, this.index);
    },
    handleInput() {
      const value = this.$refs.autocomplete.value;
      this.selectedValue = { name: value };
      this.$emit("change", this.selectedValue, this.index);
    }
  }
};
</script>

<style>
.autocomplete-input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autocomplete-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.autocomplete-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 185px;
  list-style-type: none;
  cursor: pointer;
}

.autocomplete-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
