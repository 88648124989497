<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card card-shadow">
          <div class="container-fluid">
            <div class="row">
              <div class="col text-left">
                <div class="card-body">
                  <h5 class="card-title">{{ recipe.name }}</h5>
                  <p class="card-text"></p>
                </div>
              </div>
              <div class="col text-center">
                <div class="card-body vertical-middle"></div>
              </div>
              <div class="col text-right">
                <div class="card-body">
                  <span class="emphasis">Author</span> {{ recipe.author }}
                </div>
              </div>
            </div>
            <!--<div class="row">
              <div class="col text-center">
                <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item">
                    <a
                      :class="'recipe-edit-tab nav-link emphasis '"
                      href="#"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>-->
          </div>
          <b-tabs content-class="mt-3" justified v-model="tabIndex">
            <b-tab title="Recipe" title-link-class="emphasis">
              <form id="frmEdit" action="#" method="post">
                <div class="form-group">
                  <label for="recipe-name">Name</label>
                  <input
                    name="name"
                    type="text"
                    class="form-control data-field"
                    id="recipe-name"
                    placeholder="e.g. My Recipe"
                    required
                    maxlength="100"
                    data-error="Name is required."
                    v-model="recipe.name"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    The name of the recipe (100 characters max).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-description">Description</label>
                  <textarea
                    name="description"
                    class="form-control data-field"
                    id="recipe-description"
                    placeholder="e.g. This is my awesome recipe"
                    rows="8"
                    maxlength="256"
                    v-model="recipe.description"
                  ></textarea>
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    A description of the recipe (256 characters max).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-url">Source URL</label>
                  <input
                    name="url"
                    type="text"
                    class="form-control data-field"
                    id="recipe-url"
                    placeholder="e.g. https://www.fooddelights.io/recipes/1"
                    maxlength="100"
                    v-model="recipe.url"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    Web URL where the recipe was found (if applicable).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-servings">Servings</label>
                  <input
                    name="servings"
                    type="number"
                    class="form-control data-field"
                    id="recipe-servings"
                    placeholder="e.g. 3"
                    v-model="recipe.servings"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    Number of servings recipe makes.
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-prep-time">Prep Time</label>
                  <input
                    name="prep_time"
                    type="number"
                    class="form-control data-field"
                    id="recipe-prep-time"
                    placeholder="e.g. 20"
                    v-model="recipe.prep_time"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    Estimated prep time (in minutes).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-cook-time">Cook Time</label>
                  <input
                    name="cook_time"
                    type="number"
                    class="form-control data-field"
                    id="recipe-cook-time"
                    placeholder="e.g. 30"
                    v-model="recipe.cook_time"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    Estimated cook time (in minutes).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-tags">Tags</label>
                  <b-form-tags
                    id="recipe-tags"
                    class="mb-2"
                    placeholder="Enter tags separated by space or comma"
                    separator=" ,"
                    v-model="recipe.tags"
                  ></b-form-tags>
                  <div class="help-block with-errors">
                    List of tags for organizing recipes.
                  </div>
                </div>
              </form>
            </b-tab>
            <b-tab title="Ingredients" title-link-class="emphasis">
              <div id="ingredients-container">
                <p>
                  To add an ingredient, click the
                  <span class="emphasis">ADD INGREDIENT</span> button. Enter the
                  amount of the ingredient in the amount field, and select the
                  unit from the drop-down menu. Ingredients can be rearranged by
                  clicking and dragging the six squares icon to the left of each
                  ingredient.
                </p>

                <b-button
                  variant="outline-primary"
                  class="mb-3"
                  @click="addIngredient()"
                >
                  <i class="fas fa-plus-circle"></i> Add Ingredient
                </b-button>

                <div class="form-group float-right mt-2">
                  <b>Format:</b>
                  <b-form-checkbox v-model="format" switch inline class="ml-2">
                    {{ numberFormat }}
                  </b-form-checkbox>
                </div>

                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Ingredient</th>
                      <th class="ingredients-amount-col">Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <draggable
                    id="recipe-ingredients-list"
                    tag="tbody"
                    :list="recipe.ingredients"
                    handle=".handle"
                  >
                    <tr
                      v-for="(ingredient, index) in recipe.ingredients"
                      :key="index"
                    >
                      <td class="handle recipe-handle">
                        <i class="fas fa-grip-vertical p-2"></i>
                      </td>
                      <td>
                        <autocomplete
                          :search="searchFood"
                          :get-result-value="getFoodValue"
                          :default-value="ingredient.food.name"
                          :index="index"
                          overwrite-class="recipe-ingredients-name"
                          placeholder="e.g. Lean Ground Beef"
                          @change="updateFood"
                        ></autocomplete>
                      </td>
                      <td class="ingredients-amount-col">
                        <div class="input-group">
                          <input
                            name="amount"
                            type="text"
                            class="form-control recipe-ingredients-amount ingredient-field"
                            placeholder="e.g. 1"
                            v-model="ingredient.amount"
                          />
                          <div class="input-group-append">
                            <autocomplete
                              :search="searchUnits"
                              :get-result-value="getUnitValue"
                              :default-value="formatUnit(ingredient.unit)"
                              :index="index"
                              overwrite-class="recipe-ingredients-unit"
                              placeholder="e.g. Ounces (oz)"
                              @change="updateUnit"
                            ></autocomplete>
                          </div>
                        </div>
                      </td>
                      <td>
                        <b-button
                          variant="outline-danger"
                          @click="deleteIngredient(index)"
                        >
                          <i class="far fa-trash-alt"></i>
                        </b-button>
                      </td>
                    </tr>
                  </draggable>
                </table>

                <b-button
                  variant="outline-primary"
                  class="mb-3"
                  @click="addIngredient()"
                >
                  <i class="fas fa-plus-circle"></i> Add Ingredient
                </b-button>
              </div>
            </b-tab>
            <b-tab title="Instructions" title-link-class="emphasis">
              <div id="instructions-container">
                <p>
                  To add an instruction, click the
                  <span class="emphasis">ADD STEP</span> button. The step
                  numbers will be populated automatically. Instructions can be
                  rearranged by clicking and dragging the six squares icon to
                  the left of each instruction. The step numbers will update
                  automatically after the instruction has been moved.
                </p>

                <b-button
                  variant="outline-primary"
                  class="mb-3"
                  @click="addStep()"
                >
                  <i class="fas fa-plus-circle"></i> Add Step
                </b-button>

                <draggable
                  id="recipe-instructions-list"
                  tag="ul"
                  :list="recipe.instructions"
                  class="container-fluid"
                  handle=".handle"
                  @update="updateNumerals()"
                >
                  <li
                    class="row instruction-row"
                    v-for="(instruction, index) in recipe.instructions"
                    :key="index"
                  >
                    <div class="col-ingredient-handle handle">
                      <div class="recipe-handle">
                        <i class="fas fa-grip-vertical p-2"></i>
                      </div>
                    </div>
                    <div class="col-ingredient-desc">
                      <h5 class="recipe-instructions-numeral">
                        Step {{ instruction.numeral }}
                      </h5>
                      <b-button
                        variant="outline-danger"
                        class="recipe-instruction-delete float-right"
                        @click="deleteStep(index)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </b-button>
                      <textarea
                        class="form-control recipe-instructions-textarea instruction-field"
                        type="text"
                        id="description"
                        placeholder="e.g. Preheat oven to 425..."
                        rows="6"
                        v-model="instruction.text"
                      ></textarea>
                    </div>
                  </li>
                </draggable>

                <b-button
                  variant="outline-primary"
                  class="mb-3"
                  @click="addStep()"
                >
                  <i class="fas fa-plus-circle"></i> Add Step
                </b-button>
              </div>
            </b-tab>
            <b-tab title="Images" title-link-class="emphasis">
              <div id="images-container">
                <p>
                  To add an image, click the
                  <span class="emphasis">Browse</span> button and select the
                  images in the dialog that appears. Multiple images can be
                  selected at once using the SHIFT or CTRL keys. Click
                  <span class="emphasis">Start Upload</span> to upload the
                  pictures.
                </p>

                <b-input-group class="mb-3">
                  <b-form-file
                    accept="image/*"
                    multiple
                    v-model="image"
                  ></b-form-file>
                  <b-button
                    variant="primary"
                    class="ml-2"
                    :disabled="uploading"
                    @click="uploadImage()"
                  >
                    <b-spinner small v-if="uploading"></b-spinner>
                    <span class="sr-only" v-if="uploading">Loading...</span>
                    <i
                      class="fas fa-cloud-upload-alt mr-1"
                      v-if="!uploading"
                    ></i>
                    <span v-if="!uploading">Start upload</span>
                  </b-button>
                </b-input-group>

                <div class="recipe-images card-deck mb-3">
                  <div
                    class="card recipe-image"
                    style="max-width: 20rem;"
                    v-for="(image, index) in recipe.images"
                    :key="index"
                  >
                    <b-img
                      :ref="'image-' + index"
                      class="card-img-top"
                      :src="images[index]"
                      alt="Card image cap"
                    />
                    <div class="card-body p-2">
                      <b-button
                        variant="outline-danger"
                        class="recipe-image-delete float-right"
                        v-b-modal="'modal_image_delete_' + index"
                      >
                        <i class="far fa-trash-alt"></i>
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        class="recipe-image-default float-right mr-2"
                        :pressed="image === recipe.image"
                        @click="setDefaultImage(index)"
                      >
                        <i class="fas fa-check-circle"></i> Set Default
                      </b-button>

                      <b-modal
                        :id="'modal_image_delete_' + index"
                        title="Delete Image"
                        header-bg-variant="danger"
                        header-text-variant="light"
                        ok-title="Delete"
                        ok-variant="danger"
                        @ok="deleteImage(index)"
                      >
                        <p class="my-4">
                          Are you sure you want to delete this image?
                        </p>
                      </b-modal>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Sharing" title-link-class="emphasis"
              ><div id="sharing-container" class="container-fluid">
                <div class="row">
                  <div class="col">
                    <form>
                      <div class="form-group">
                        <label for="recipe-public-status"
                          >Recipe Visibility</label
                        >
                        <div class="input-group">
                          <b-form-checkbox switch v-model="recipe.public"
                            ><span class="explain-text">{{
                              recipeVisibility
                            }}</span></b-form-checkbox
                          >
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="recipe-share-link">Share Link</label>
                        <div class="input-group mb-3">
                          <input
                            name="url"
                            type="text"
                            class="form-control"
                            id="recipe-share-link"
                            placeholder="e.g. https://www.fooddelights.io/recipes/1"
                            :value="shareLink"
                            readonly
                          />
                          <div class="input-group-append">
                            <b-button
                              variant="outline-secondary"
                              @click="copyLink()"
                            >
                              {{ copyButton }}
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row recipe-sharing-row">
                  <div class="col text-left">
                    <div class="form-group">
                      <h5 class="card-title">Permissions</h5>
                      <p class="card-text">
                        Families in this list can view/edit this recipe.
                      </p>
                      <b-input-group class="mb-3">
                        <b-form-input
                          placeholder="Email Address"
                          aria-label="Email Address"
                          aria-describedby="family-send-invite"
                          v-model="shareEmail"
                        >
                        </b-form-input>
                        <b-input-group-append>
                          <b-button
                            variant="outline-primary"
                            @click="shareRecipe()"
                            class="rounded-right"
                            ><i class="fas fa-share-alt"></i> Share</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <ul id="sharing-family-rows" class="list-group mb-3">
                      <li
                        :id="'sharing-family-' + family.id"
                        class="list-group-item d-flex justify-content-between align-items-center sharing-family-row"
                        v-for="(family, key) in recipe.sharing"
                        :key="key"
                      >
                        <span>{{ family.name }}</span>
                        <div>
                          <b-form-checkbox
                            v-model="family.edit"
                            switch
                            class="float-left mt-1 mr-3 text-muted"
                          >
                            <i>{{ family.edit ? "Edit" : "View" }}</i>
                          </b-form-checkbox>
                          <b-button
                            variant="outline-danger"
                            class="float-right"
                            @click="removeShare(key)"
                          >
                            <i class="far fa-trash-alt"></i>
                          </b-button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div></div
            ></b-tab>
          </b-tabs>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <b-button
                variant="outline-primary"
                class="recipe-exit mr-1"
                @click="exit()"
              >
                <i class="far fa-arrow-alt-circle-left"></i> Exit
              </b-button>
              <b-button
                variant="outline-success"
                class="recipe-save mr-1"
                @click="save()"
              >
                <i class="far fa-save"></i> Save
              </b-button>
              <b-button
                variant="outline-danger"
                class="recipe-delete"
                v-b-modal.modal_recipe_delete
              >
                <i class="far fa-trash-alt"></i> Delete
              </b-button>

              <b-modal
                id="modal_exit_saving"
                title="Save before exiting?"
                header-bg-variant="primary"
                header-text-variant="light"
                @ok="saveAndExit"
                @close="exitWithoutSaving"
              >
                <template v-slot:modal-footer="{ ok, cancel, close }">
                  <b-button size="sm" variant="primary" @click="ok()">
                    Save &amp; Exit
                  </b-button>
                  <b-button size="sm" @click="close()">
                    Exit without saving
                  </b-button>
                  <b-button size="sm" @click="cancel()">
                    Cancel
                  </b-button>
                </template>
                <p>
                  Do you wish to save before exiting? If not, the changes to
                  your recipe will be lost.
                </p>
              </b-modal>

              <b-modal
                id="modal_recipe_delete"
                title="Delete Recipe"
                header-bg-variant="danger"
                header-text-variant="light"
                ok-title="Delete"
                ok-variant="danger"
                @ok="deleteRecipe()"
              >
                <p>
                  Are you sure you want to delete recipe {{ recipe.name }}? This
                  cannot be undone.
                </p>
              </b-modal>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
// import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Autocomplete from "../../components/Autocomplete";
import Recipe from "../../firebase/recipes";
import Fraction from "fraction.js";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "RecipeEdit",
  mixins: [FirebaseMixin, UtilsMixin],
  components: { autocomplete: Autocomplete, draggable: draggable },
  data() {
    return {
      ref: "recipes",
      bind: ["units", "food"],
      refkey: null,
      slug: null,
      recipe: {
        name: "",
        description: "",
        url: "",
        servings: "",
        prep_time: "",
        cook_time: "",
        public: true,
        tags: [
          {
            id: 0,
            name: "Chicken"
          }
        ],
        images: [],
        ingredients: [],
        instructions: []
      },
      original: {},
      tags: [
        {
          id: 0,
          name: "Chicken"
        }
      ],
      tabIndex: 0,
      format: false,
      image: null,
      images: [],
      uploading: false,
      placeholder: require("@/assets/recipe-placeholder.jpg"),
      copyButton: "Copy",
      shareEmail: ""
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userNumberFormat", "userUnitSystem", "familyFood"]),
    familyKey() {
      return this.userProfile.family_key;
    },
    numberFormat() {
      return this.format ? "Fractions" : "Decimals";
    },
    unitLabels() {
      const _this = this;
      return this.units[this.userUnitSystem].map(unit => {
        return _this.formatUnit(unit);
      });
    },
    recipeVisibility() {
      return this.recipe.public
        ? "Anyone can view this recipe."
        : "Only those with permission can view this recipe.";
    },
    shareLink() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/recipes/" +
        this.$route.params.slug
      );
    },
    foodList() {
      let food;
      try {
        food = this.createUniqueList(this.food, this.familyFood);
      } catch (e) {
        console.log(e);
        food = [];
      }
      return food;
    }
  },
  watch: {
    refkey: {
      immediate: false,
      handler(key) {
        this.bindObject(this.ref, key, "recipe").then(() => {
          this.original = JSON.stringify(this.recipe);
        });
      }
    },
    tabIndex: {
      immediate: false,
      handler(tabIndex) {
        switch (tabIndex) {
          case 0:
            history.replaceState({}, null, "/recipes/" + this.slug + "/edit");
            break;
          case 1:
            history.replaceState(
              {},
              null,
              "/recipes/" + this.slug + "/edit/ingredients"
            );
            break;
          case 2:
            history.replaceState(
              {},
              null,
              "/recipes/" + this.slug + "/edit/instructions"
            );
            break;
          case 3:
            history.replaceState(
              {},
              null,
              "/recipes/" + this.slug + "/edit/images"
            );
            break;
          case 4:
            history.replaceState(
              {},
              null,
              "/recipes/" + this.slug + "/edit/sharing"
            );
            break;
          default:
            history.replaceState({}, null, "/recipes/" + this.slug + "/edit");
        }
      }
    },
    format: {
      immediate: true,
      handler() {
        this.changeNumberFormat();
      }
    },
    recipe: {
      immediate: true,
      handler(recipe) {
        this.images = [];
        if (!Array.isArray(recipe.images)) {
          recipe.images = [];
          this.$set(recipe, "images", []);
        }
        recipe.images.forEach((image, index) => {
          this.images[index] = this.placeholder;
          this.getImageUrl("recipes/" + image).then(url => {
            this.images[index] = url;
            this.$refs["image-" + index][0].src = url;
          });
        });
        if (!Array.isArray(this.recipe.ingredients)) {
          this.recipe.ingredients = [];
          this.$set(recipe, "ingredients", []);
        }
        if (this.recipe.ingredients.length === 0) {
          this.addIngredient();
        }
        if (!Array.isArray(this.recipe.instructions)) {
          this.recipe.instructions = [];
          this.$set(recipe, "instructions", []);
        }
        if (this.recipe.instructions.length === 0) {
          this.addStep();
        }
      }
    }
  },
  methods: {
    exit() {
      const saved = JSON.stringify(this.recipe) === this.original;
      if (saved) {
        this.navigate("recipes");
      } else {
        this.$bvModal.show("modal_exit_saving");
      }
    },
    save() {
      const recipe = { ...this.recipe };
      const listRecipe = this.getListRecipe(recipe);

      let resp;
      if (recipe.public) {
        resp = this.updateObject("public_recipes", this.refkey, listRecipe);
      } else {
        resp = this.deleteObject("public_recipes", this.refkey);
      }
      return Promise.all([
        this.updateObject(this.ref, this.refkey, recipe),
        this.updateObject(
          "families/" + this.familyKey + "/recipes",
          this.refkey,
          listRecipe
        ),
        resp,
        this.updateTags(recipe)
      ])
        .then(() => {
          this.original = JSON.stringify(this.recipe);
          window.toastr.success("Recipe saved successfully");
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while saving the recipe. Please try again later."
          );
        });
    },
    updateTags(recipe) {
      const recipeKey = String(this.refkey);
      const tagPath = "families/" + this.familyKey + "/tags";
      return this.getObjectSnapshot(tagPath).then(tags => {
        tags = !Array.isArray(tags) ? [] : tags;
        if (Array.isArray(recipe.tags)) {
          recipe.tags.forEach(recipeTag => {
            if (recipeTag in tags) {
              if (!tags[recipeTag].includes(recipeKey)) {
                tags[recipeTag].push(recipeKey);
              }
            } else {
              tags[recipeTag] = [recipeKey];
            }
          });
        }
        return this.updateObject(tagPath, null, tags, true);
      });
    },
    exitWithoutSaving() {
      this.navigate("recipes");
    },
    saveAndExit() {
      this.save().then(() => {
        this.navigate("recipes");
      });
    },
    deleteRecipe() {
      Promise.all([
        this.deleteObject(
          "families/" + this.userProfile.family_key + "/recipes",
          this.refkey
        ),
        this.deleteObject("public_recipes", this.refkey)
      ])
        .then(() => {
          this.deleteObject(this.ref, this.refkey)
            .then(() => {
              window.toastr.success(
                "The recipe has been deleted successfully."
              );
            })
            .catch(error => {
              console.log(error);
              window.toastr.error(
                "An error occurred while deleting the recipe. Please try again later."
              );
            });
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while deleting the recipe. Please try again later."
          );
        });
    },
    setTab(name = null) {
      name = String(name).toLowerCase();
      console.log("Tab: " + name);
      switch (name) {
        case "recipe":
          this.tabIndex = 0;
          break;
        case "ingredients":
          this.tabIndex = 1;
          break;
        case "instructions":
          this.tabIndex = 2;
          break;
        case "images":
          this.tabIndex = 3;
          break;
        case "sharing":
          this.tabIndex = 4;
          break;
        default:
          this.tabIndex = 0;
      }
    },
    addIngredient() {
      const ingredient = {
        food: {
          name: ""
        },
        unit: {
          name: "",
          label: ""
        },
        amount: null
      };
      if (!Array.isArray(this.recipe.ingredients)) {
        this.recipe.ingredients = [];
      }
      this.recipe.ingredients.push(ingredient);
      this.$forceUpdate();
    },
    deleteIngredient(index) {
      this.recipe.ingredients.splice(index, 1);
    },
    searchUnits(input) {
      if (input.length < 1) {
        return [];
      }
      return this.units[this.userUnitSystem].filter(unit => {
        return (
          unit.name.toLowerCase().startsWith(input.toLowerCase()) ||
          unit.label.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    formatUnit(unit) {
      const label = unit.name + " (" + unit.label + ")";
      return label.replace(" ()", "");
    },
    getUnitValue(result) {
      return this.formatUnit(result);
    },
    updateUnit(value, index) {
      const unit = { ...value };
      this.recipe.ingredients[index].unit = unit;
    },
    searchFood(input) {
      if (input.length < 1) {
        return [];
      }
      return this.foodList.filter(food => {
        return food.name.toLowerCase().startsWith(input.toLowerCase());
      });
    },
    getFoodValue(result) {
      return result.name;
    },
    updateFood(value, index) {
      const food = { ...value };
      this.recipe.ingredients[index].food = food;
    },
    changeNumberFormat() {
      console.log("Number Format: " + this.numberFormat);
      if (this.format) {
        // Fractions
        this.recipe.ingredients.forEach(ingredient => {
          const f = new Fraction(ingredient.amount);
          ingredient.amount = f.toFraction(true);
        });
      } else {
        // Decimals
        this.recipe.ingredients.forEach(ingredient => {
          const f = new Fraction(ingredient.amount);
          ingredient.amount = f.valueOf();
        });
      }
    },
    addStep() {
      const numeral = this.recipe.instructions.length + 1;
      const instruction = {
        numeral: numeral,
        text: ""
      };
      if (!Array.isArray(this.recipe.instructions)) {
        this.recipe.instructions = [];
      }
      this.recipe.instructions.push(instruction);
      this.$forceUpdate();
    },
    deleteStep(index) {
      this.recipe.instructions.splice(index, 1);
    },
    updateNumerals() {
      this.recipe.instructions.forEach((instruction, index) => {
        instruction.numeral = index + 1;
      });
    },
    uploadImage() {
      const reader = new FileReader();
      const files = this.image;

      // Start button loading spinner
      this.uploading = true;

      if (files.length === 0) {
        // Stop button loading spinner
        this.uploading = false;
      } else {
        files.forEach(file => {
          const extension = file.name.split(".").pop();
          const filename = this.refkey + "-" + uuidv4() + "." + extension;
          console.log("File: " + filename);

          // // Get storage reference
          // const ref = firebase
          //   .storage()
          //   .ref()
          //   .child("recipes/" + filename);

          reader.addEventListener(
            "load",
            () => {
              if (!Array.isArray(this.recipe.images)) {
                this.recipe.images = [];
              }

              // ref.put(file).then(() => {
              //   this.recipe.images.push(filename);
              //   this.save().then(() => {
              //     // Stop button loading spinner
              //     this.uploading = false;
              //   });
              // });
              const r = new Recipe();
              r.uploadImage(this.refkey, filename, reader.result)
                .then(() => {
                  this.recipe.images.push(
                    this.recipe.family_key + "/" + filename
                  );
                  this.save().then(() => {
                    // Stop button loading spinner
                    this.uploading = false;
                  });
                })
                .catch(error => {
                  console.log("UploadImage", error);
                  window.toastr.error(
                    "Unable to upload image: Please try again later."
                  );
                });
            },
            false
          );

          if (file) {
            //reader.readAsBinaryString(file);
            reader.readAsDataURL(file);
          }
        });
      }
    },
    deleteImage(index) {
      // const ref = firebase
      //   .storage()
      //   .ref()
      //   .child("recipes/" + image);
      // ref
      //   .delete()
      //   .then(() => {
      //     this.recipe.images.splice(index, 1);
      //     this.save();
      //   })
      //   .catch(error => {
      //     if (error.code === "storage/object-not-found") {
      //       this.recipe.images.splice(index, 1);
      //       this.save();
      //     } else {
      //       console.log(error);
      //       window.toastr.error(
      //         "An error occurred while deleting the image. Please try again later."
      //       );
      //     }
      //   });

      const r = new Recipe();
      r.deleteImage(this.refkey, index)
        .then(() => {
          this.recipe.images.splice(index, 1);
          this.save();
        })
        .catch(error => {
          console.log("DeleteImage", error);
          window.toastr.error(
            "An error occurred while deleting the image. Please try again later."
          );
        });
    },
    setDefaultImage(index) {
      const image = this.recipe.images[index];
      this.recipe.image = image;
    },
    copyLink() {
      document.querySelector("#recipe-share-link").select();
      document.execCommand("copy");
      this.copyButton = "Copied!";
      setTimeout(() => {
        this.copyButton = "Copy";
      }, 5000);
    },
    getListRecipe(recipe) {
      const listRecipe = {
        name: recipe.name,
        description: recipe.description,
        author: recipe.author,
        slug: recipe.slug,
        family_key: recipe.family_key
      };
      if (recipe.image) {
        listRecipe.image = recipe.image;
      }
      return listRecipe;
    },
    shareRecipe() {
      if (!this.validateEmail(this.shareEmail)) {
        window.toastr.error("Please enter a valid email");
        return false;
      }
      const r = new Recipe();
      r.share(this.refkey, this.shareEmail)
        .then(() => {
          this.shareEmail = "";
          window.toastr.success("Recipe shared successfully");
        })
        .catch(error => {
          console.log("ShareRecipe", error);
          window.toastr.error("Unable to share recipe: user not found");
        });
    },
    removeShare(familyKey) {
      const r = new Recipe();
      r.remove(this.refkey, familyKey)
        .then(() => {
          this.shareEmail = "";
          window.toastr.success("Recipe sharing removed");
        })
        .catch(error => {
          console.log("removeShare", error);
          window.toastr.error(
            "An error occurred while removing the share. Please try again later."
          );
        });
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    this.getIdFromSlug(this.slug).then(id => {
      this.refkey = id;
    });
    this.setTab(this.$route.params.tab);
  }
};
</script>

<style>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.recipe-card-body-text {
  height: 130px;
  overflow: hidden;
  margin-bottom: 10px;
}

.recipe-row {
  padding-top: 4px;
  padding-left: 9px;
  padding-right: 9px;
}

.recipe-card {
  margin: 0;
  padding: 5px;
  min-width: 20%;
}

.card-img-top {
  height: 150px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-link {
  color: rgb(255, 152, 0);
  font-weight: 500;
}

#recipe-tags {
  margin-left: 0px;
}

.recipe-tag {
  margin-left: 5px;
}

.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #424242;
}

.chip img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.chip-sm {
  display: inline-block;
  padding: 0;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
}

.chip-sm img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.gold-star {
  color: gold;
  font-size: 1.2em;
}

#recipe-carousel {
  height: 300px;
  max-width: 600px;
  background-color: #424242;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

#recipe-carousel .carousel-inner {
  height: inherit;
  max-width: inherit;
}

.recipe-carousel-image {
  object-fit: contain;
  object-position: 50% 50%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.form-inline .form-group {
  margin-left: 0;
}

#recipe-edit-tags .custom-select {
  width: auto;
}

.recipe-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}

.ordered-list-group {
  list-style: decimal inside;
}

.ordered-list-group-item {
  display: list-item;
}

#ingredients-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#recipe-ingredients-list .btn-outline-secondary {
  color: #495057;
  background-color: white;
  background-image: none;
  border-color: #ced4da;
}

#ingredients th {
  border-top: 0;
}

.ingredients-amount-col {
  min-width: 285px;
}

.recipe-ingredients-amount {
  max-width: 100px;
  float: left;
}

.recipe-ingredients-name-input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.recipe-ingredients-name-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.recipe-ingredients-name-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 185px;
  list-style-type: none;
  cursor: pointer;
}

.recipe-ingredients-name-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.recipe-ingredients-unit {
  max-width: 185px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.recipe-ingredients-unit-input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 185px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.recipe-ingredients-unit-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.recipe-ingredients-unit-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  max-width: 185px;
  list-style-type: none;
  cursor: pointer;
}

.recipe-ingredients-unit-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#instructions-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#instructions .list-group-item {
  border: 0;
}

#recipe-instructions-list {
  list-style-type: none;
}

#recipe-instructions-list > li {
  min-height: 108px;
}

#recipe-instructions-list .row {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

#recipe-instructions-list .row:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: 22px;
}

#recipe-instructions-list .row:last-of-type {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 22px;
}

.col-ingredient-handle {
  width: 30px;
  margin-right: 5px;
  padding-top: 7px;
}

.col-ingredient-desc {
  width: calc(100% - 35px);
}

.col-ingredient-delete {
  width: 72px;
  margin-left: 5px;
}

.recipe-instructions-numeral {
  float: left;
  height: 38px;
  padding-top: 8px;
}

.recipe-instructions-textarea {
  height: 100px;
  width: 100%;
}

#images-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.explain-text {
  font-style: italic;
  margin-top: 5px;
  margin-left: 10px;
}

.recipe-sharing-row {
  margin-top: 15px;
}

#sharing-family-rows {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.emphasis {
  font-weight: 500 !important;
}
</style>
